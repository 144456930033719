import { Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HereMapsApi } from '@web/shared/data-access/model';

@Pipe({
  name: 'formatCompanyAddress',
  standalone: true,
})
export class FormattedCompanyAddressPipe implements PipeTransform {
  public transform(address: HereMapsApi.AddressData | undefined): string {
    if (!address) {
      return '';
    }

    let addressStr = '';

    if (this.isAddressPartValid(address.street)) {
      addressStr += `${address.street}`;
    }

    if (this.isAddressPartValid(address.houseNumber)) {
      if (addressStr) {
        addressStr += ` ${address.houseNumber}`;
      } else {
        addressStr += `${address.houseNumber}`;
      }
    }

    if (this.isAddressPartValid(address.postalCode)) {
      if (addressStr) {
        addressStr += `, ${address.postalCode}`;
      } else {
        addressStr += `${address.postalCode}`;
      }
    }

    if (this.isAddressPartValid(address.city)) {
      if (addressStr) {
        addressStr += ` ${address.city}`;
      } else {
        addressStr += `${address.city}`;
      }
    }

    if (this.isAddressPartValid(address.state)) {
      if (addressStr) {
        addressStr += `, ${address.state}`;
      } else {
        addressStr += `${address.state}`;
      }
    }

    //  Show county only if there is no other data visible
    if (this.isAddressPartValid(address.county) && !addressStr.length) {
      addressStr += `${address.county}`;
    }

    return addressStr;
  }

  //  TODO: Remove all 'Not applicable' strings from codebase, refactor
  //  TODO: Remove all 'Not applicable' strings from codebase, refactor
  //  TODO: Remove all 'Not applicable' strings from codebase, refactor
  //  TODO: Remove all 'Not applicable' strings from codebase, refactor
  //  TODO: Remove all 'Not applicable' strings from codebase, refactor
  /**
   * Checks if part of address can be used in the UI elements
   *
   * @param addressPart
   * @private
   */
  private isAddressPartValid(addressPart: string | null | undefined): string | null {
    if (!addressPart || addressPart === 'Not applicable') {
      return null;
    }

    return addressPart;
  }
}
