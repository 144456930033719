// noinspection TypeScriptUnresolvedReference

import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { AuthApi } from '@web/shared/data-access/model';
import { ApplicantAuthInterceptor, ApplicantRefreshTokenInterceptor } from '@web/web/applicant/core/auth/data-access';
import { CLIENT_INJECTION_TOKEN, ErrorInterceptor, LoadingInterceptor } from '@web/web/core/auth/data-access';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import {
  BASE_URL_TOKEN,
  GOOGLE_LOGIN_REDIRECT_URL,
  GOOGLE_REGISTER_REDIRECT_URL,
  HERE_MAPS_API_KEY_TOKEN,
  HERE_MAPS_APP_ID_TOKEN,
} from '@web/web/shared/data-access/api';
import { AlertContainerComponent } from '@web/web/shared/ui/alert-container';
import { FeatherIconModule } from '@web/web/shared/ui/feather-icon';
import { ProgressBarComponent } from '@web/web/shared/ui/progress-bar';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, {
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
      onSameUrlNavigation: 'reload',
    }),
    FeatherIconModule,
    WebCoreI18nModule,
    ProgressBarComponent,
    AlertContainerComponent,
    BrowserAnimationsModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictActionSerializability: true,
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionTypeUniqueness: true,
        },
      },
    ),
    // StoreDevtoolsModule.instrument({ maxAge: 25 }),
    EffectsModule.forRoot(),
  ],
  providers: [
    { provide: CLIENT_INJECTION_TOKEN, useValue: AuthApi.Client.APPLICANT_APP },
    DecimalPipe,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    {
      provide: BASE_URL_TOKEN,
      useValue: `${process.env['BASE_URL']}`,
    },
    {
      provide: GOOGLE_LOGIN_REDIRECT_URL,
      useValue: `${process.env['GOOGLE_LOGIN_REDIRECT_URL']}`,
    },
    {
      provide: GOOGLE_REGISTER_REDIRECT_URL,
      useValue: `${process.env['GOOGLE_REGISTER_REDIRECT_URL']}`,
    },
    {
      provide: HERE_MAPS_APP_ID_TOKEN,
      useValue: `${process.env['HERE_MAPS_APP_ID']}`,
    },
    {
      provide: HERE_MAPS_API_KEY_TOKEN,
      useValue: `${process.env['HERE_MAPS_API_KEY']}`,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicantAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicantRefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
