import { AdminApi } from './admin-api.namespace';
import { ApplicantApi } from './applicant-api.namespace';
import { EmployerApi } from './employer-api.namespace';
import { PromoterApi } from './promoter-api.namespace';

export namespace AuthApi {
  export interface LoginDto {
    email: string;
    password: string;
    client?: Client;
  }

  export interface LoginWithPhoneDto {
    phone: string;
    code: string;
    client?: Client;
  }

  export interface RegisterDto {
    firstname: string;
    lastname: string;
    phoneNumber: string;
    email: string;
    password: string;
  }

  export interface ChangePassword {
    old: string;
    updated: string;
  }

  export type ChangeEmail = ChangePassword;

  export interface TokenPair {
    accessToken: string;
    refreshToken: string;
  }

  export interface BaseLoginResponseDto {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    accessToken: string;
    refreshToken: string;
  }

  export interface BasePhoneLoginResponseDto {
    id: string;
    phone: string;
    firstName: string;
    lastName: string;
  }

  export enum Client {
    ADMIN_APP = 'ADMIN_APP',
    APPLICANT_APP = 'APPLICANT_APP',
    EMPLOYER_APP = 'EMPLOYER_APP',
    PROMO_APP = 'PROMO_APP',
  }

  export const GOOGLE_FAILED_LOGIN = 'failed-google-login';

  export const GOOGLE_DENIED_PERMISSION = 'denied-google-permission';

  export const REFRESH_TOKEN_ENDPOINT_URL = 'refresh-token';

  export type AUTH_ROLE = EmployerApi.Role | AdminApi.Role | ApplicantApi.Role | PromoterApi.Role;
}
